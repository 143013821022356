export const APP_CONFIG = {
  SERVER_URI: process.env.REACT_APP_SERVER_URI || 'https://heb-cms-prod.eastus.cloudapp.azure.com',
  X_API_KEY: process.env.REACT_APP_X_API_KEY,
  ENV: process.env.REACT_APP_ENVIRONMENT,
  MAINTENANCE_MODE: process.env.REACT_APP_MAINTENANCE_MODE,
  FIREBASE_CONFIG: {
    API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
    AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    DATABASE_URL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
    MINIMUM_FETCH_INTERVAL_MILLIS: process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_MINIMUM_FETCH_INTERVAL_MILLIS
  }
}
