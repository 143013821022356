import dayjs from 'dayjs'
import 'dayjs/locale/es'
dayjs.locale('es-mx')

/**
 * Checks if both provided dates are today.
 * @param {Date | string} date1 - The first Date object or date string to compare.
 * @param {Date | string} date2 - The second Date object or date string to compare.
 * @returns {boolean} True if both dates are today, false otherwise.
 */
export function areBothDatesToday (date1, date2) {
  const today = dayjs().startOf('day')
  const dayjsDate1 = dayjs(date1).startOf('day')
  const dayjsDate2 = dayjs(date2).startOf('day')

  return dayjsDate1.isSame(today, 'day') && dayjsDate2.isSame(today, 'day')
}

/**
 * Checks if two dates are on the same day.
 * @param {Date | string} date1 - The first Date object or date string to compare.
 * @param {Date | string} date2 - The second Date object or date string to compare.
 * @returns {boolean} True if both dates are on the same day, false otherwise.
 */
export function areDatesOnTheSameDay (date1, date2) {
  const dayjsDate1 = dayjs(date1).startOf('day')
  const dayjsDate2 = dayjs(date2).startOf('day')

  return dayjsDate1.isSame(dayjsDate2, 'day')
}

/**
 * Formats a given date into the specified format.
 * @param {Date | string} date - The Date object or date string to format.
 * @param {string} format - The format string (e.g., "YYYY-MM-DD", "DD/MM/YYYY", etc.).
 * @returns {string} The formatted date string.
 */
export function formatDate (date, format) {
  return dayjs(date).format(format)
}
