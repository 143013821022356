import { initializeApp, getApps } from '@firebase/app'
import { APP_CONFIG } from '../config/app'
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
  setCustomSignals
} from '@firebase/remote-config'

const {
  API_KEY: apiKey,
  AUTH_DOMAIN: authDomain,
  DATABASE_URL: databaseUrl,
  PROJECT_ID: projectId,
  STORAGE_BUCKET: storageBucket,
  MESSAGING_SENDER_ID: messagingSenderId,
  APP_ID: appId,
  MINIMUM_FETCH_INTERVAL_MILLIS: minimumFetchIntervalMillis
} = APP_CONFIG.FIREBASE_CONFIG

const firebaseConfig = {
  apiKey,
  authDomain,
  databaseUrl,
  projectId,
  storageBucket,
  messagingSenderId,
  appId
}

export const ConfigAppService = () => {
  const initApp = () => {
    const apps = getApps()

    if (apps.length) {
      return apps[0]
    } else {
      return initializeApp(firebaseConfig)
    }
  }

  const setDevMode = async (remoteConfig) => {
    const { ENV: env = 'PRODUCTION' } = APP_CONFIG

    if (env !== 'PRODUCTION') {
      const customSignals = {
        isQAMode: 'true'
      }
      await setCustomSignals(remoteConfig, customSignals)
    }
  }

  const mapper = (result = {}) => {
    return {
      isActive: result.isActive ?? false,
      startDate: result.startDate ?? null,
      endDate: result.endDate ?? null,
      mainImage: result.mainImage ?? null,
      message: result.message ?? null,
      title: result.title ?? null
    }
  }

  const isMaintenanceMode = async () => {
    const isMaintenanceMode = APP_CONFIG.MAINTENANCE_MODE === 'true'

    if (!isMaintenanceMode) {
      return mapper({
        isActive: false
      })
    }

    try {
      const app = initApp()
      const remoteConfig = getRemoteConfig(app)
      remoteConfig.settings.minimumFetchIntervalMillis =
        minimumFetchIntervalMillis

      await setDevMode(remoteConfig)
      await fetchAndActivate(remoteConfig)
      const value = getValue(remoteConfig, 'MaintenanceMode')

      const result = JSON.parse(value.asString())

      return mapper({ ...result, isActive: isMaintenanceMode })
    } catch (error) {
      console.error('Error fetching remote config:', error)
      return mapper({
        isActive: false
      })
    }
  }

  return {
    isMaintenanceMode
  }
}
