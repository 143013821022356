import React, { useEffect, useState } from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import Login from './components/Login'
import SignUp from './components/SignUp'
import ResetPassword from './components/ResetPassword'
import ChangePassword from './components/ChangePassword'
import Passwordless from './components/Passwordless'
import LoginSuccess from './components/LoginSuccess'
import Logo from './images/heb_logo.png'
import { ClientContext } from './context/client-context'
import { changeFavicon } from './helpers/change-favicon'
import { ConfirmEmail } from './components/ConfirmEmail'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './CustomReactToastifyStyles.css'
import { ConfigAppService } from './services/config-app-service'
import MaintenanceMode from './components/MaintenanceMode'

function App () {
  const queryParams = new URLSearchParams(window.location.search)
  const client = queryParams.get('client')
  const isMiTiendaCookie = sessionStorage.getItem('isMiTienda') === 'true'
  const isMiTienda = client ? (client === 'Mi Tienda') : isMiTiendaCookie
  document.title = isMiTienda ? 'Mi Tienda' : 'HEB'
  changeFavicon(isMiTienda ? '/assets/mi-tienda/favicon.ico' : '/favicon.ico')
  if (client) {
    sessionStorage.removeItem('isMiTienda')
    sessionStorage.setItem('isMiTienda', String(isMiTienda))
  }

  const [isMaintenanceMode, setIsMaintenanceMode] = useState(null)

  useEffect(() => {
    const checkMaintenance = async () => {
      try {
        const maintenanceModeConfig =
          await ConfigAppService().isMaintenanceMode()
        setIsMaintenanceMode(maintenanceModeConfig)
      } catch (error) {
        console.error('Error:', error)
      }
    }
    checkMaintenance()
  }, [])

  if (isMaintenanceMode === null) {
    return (
      <div className="main-loader">
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    )
  }

  if (isMaintenanceMode.isActive) {
    return <MaintenanceMode maintenanceMode={isMaintenanceMode} />
  }

  return (
    <>
      <ToastContainer />
      <div className='App'>
        <div className="align-items-center container-size">
          <div className="row row-login justify-content-center">
            <div className="col-12 login-header text-center">
              <img
                alt="logo"
                src={!isMiTienda ? Logo : '/assets/mi-tienda.svg' }
                className={'logo-header' + (isMiTienda ? '-mi-tienda' : '')} />
            </div>
            <div className="col-sm-11 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
              <ClientContext.Provider value={{ isMiTienda }}>
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/confirm-email" element={<ConfirmEmail />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/changepassword" element={<ChangePassword />} />
                  <Route path="/passwordless" element={<Passwordless />} />
                  <Route path="/login-success" element={<LoginSuccess />} />
                </Routes>
              </ClientContext.Provider>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default App
